import React, { useEffect, useState } from 'react'
import { Col, message, Row } from 'antd'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { ConfirmWithChild } from 'Components/modal'
import {
  formValidation,
  getBranchMenuSelect,
  getReasonhMenuSelect,
  isFormError,
  isFormRequired,
  toDateQuery,
} from 'helpers/format'
import { getTaskLogsAPI, updateToBranchAPI } from 'Store/chaiyo-customer/api'
import { LogReq, SendToBranchReq } from 'Store/chaiyo-customer/types'
import { selectLoginData } from 'Store/user/selector'
import { selectLogsData, selectLogsEvent } from 'Store/chaiyo-customer/selector'
import { AppDateItem, AppInput, AppSelect } from 'Components/form'
import { cloneDeep } from 'lodash'
import { selectBranchList, selectReasonList } from 'Store/sale-task/selector'
import { getBranchListAPI, getReasonListAPI } from 'Store/sale-task/api'
import { BranchReq, ReasonReq } from 'Store/sale-task/types'
import { SaleTaskStatus } from 'helpers/constanst'
import { SearchOutlined } from '@ant-design/icons'
import { MyTheme } from 'Components/common/Theme'
import styled from 'styled-components'
import { appConfig } from 'config'

type Props = {
  isShow: boolean
  onClose: () => void
  id: number
} & ReduxProps

const SendToBranchModal = (props: Props) => {
  const { isShow, onClose, id, loginData, reasonList, branchList } = props

  const initForm = {
    branch_customer: '',
    branch_customer_reason: '',
    branch_customer_date: '',
    branch_customer_email: '',
    branch_customer_note: '',
  } as SendToBranchReq

  const requireFeild = [
    'branch_customer',
    'branch_customer_reason',
    'branch_customer_date',
    'branch_customer_email',
  ]
  const [formData, setFormData] = useState(cloneDeep(initForm))
  const [formError, setFormError] = useState([] as string[])

  const onSendToBranch = (): void => {
    const req: SendToBranchReq = {
      ...formData,
      status: SaleTaskStatus.TOBRANCH,
      branch_customer_date: toDateQuery(formData.branch_customer_date),
      id,
    }
    dispatch(updateToBranchAPI(req, loginData?.access_token || ''))
  }

  const onUpdateData = (val: any, key: string) => {
    if (key === 'mobile' && val.length > 10) return
    setFormData({
      ...formData,
      [key]: val,
    })
  }

  const onGetBranchListAPI = () => {
    const req: BranchReq = {}
    dispatch(getBranchListAPI(req, loginData?.access_token || ''))
  }

  const onGetReasonListAPI = () => {
    const req: ReasonReq = {}
    dispatch(getReasonListAPI(req, loginData?.access_token || ''))
  }

  useEffect(() => {
    if (isShow && loginData?.access_token) {
      setFormData({ ...cloneDeep(initForm) })
      onGetBranchListAPI()
      onGetReasonListAPI()
    }
  }, [isShow])

  useEffect(() => {
    if (formError.length > 0) {
      validateForm()
    }
  }, [formData])

  const validateForm = () => {
    const error = formValidation(requireFeild, formData)
    setFormError([...error])
    return error.length > 0 ? false : true
  }

  const isRequired = (name: string) => {
    return isFormRequired(requireFeild, name)
  }

  const isError = (name: string) => {
    return isFormError(formError, name)
  }

  const dispatch = useDispatch()

  const onGetDataList = (): void => {
    const req: LogReq = {
      id: id,
    }
    dispatch(getTaskLogsAPI(req, loginData?.access_token || ''))
  }

  const findEmailByName = (val: string) => {
    const branch = branchList && branchList.find(item => item.name === val)
    const email = []
    if (branch?.email_center) {
      email.push(branch?.email_center)
    }
    if (branch?.email_staff1) {
      email.push(branch?.email_staff1)
    }
    if (branch?.email_staff2) {
      email.push(branch?.email_staff2)
    }
    if (branch?.email_staff3) {
      email.push(branch?.email_staff3)
    }
    return email.join(',')
  }

  const onUpdateBranch = (value: any) => {
    setFormData({
      ...formData,
      branch_customer: value,
      branch_customer_email: findEmailByName(value),
    })
  }

  useEffect(() => {
    if (isShow) {
      onGetDataList()
    }
  }, [isShow, id])

  const getExternalLink = () => {
    return (
      <ExternalLink href={appConfig.externalChaiyoLink} target="_blank" rel="noopener noreferrer">
        &nbsp;
        <SearchOutlined /> รายละเอียด และคู่มือผลิตภัณฑ์ประกันชีวิต
      </ExternalLink>
    )
  }

  const getDailogChild = () => {
    return (
      <Row justify="space-between" gutter={[16, 1]}>
        <Col md={24} xs={24}>
          {getExternalLink()}
        </Col>
        <Col md={24} xs={24}>
          <AppSelect
            name="branch_customer"
            label="เจ้าหน้าที่ในพื้นที่บริการ เลือกเจ้าหน้าที่ในพื้นที่บริการ"
            isRequired={isRequired('branch_customer')}
            isError={isError('branch_customer')}
            value={formData.branch_customer}
            onChange={onUpdateBranch}
            menu={getBranchMenuSelect(branchList)}
          />
        </Col>
        <Col span={24}>
          <AppDateItem
            label="วันที่ลูกค้าต้องการเข้า"
            placeHolder="วันที่ลูกค้าต้องการเข้า"
            isRequired={isRequired('branch_customer_date')}
            isError={isError('branch_customer_date')}
            onChange={(date: string) => onUpdateData(date, 'branch_customer_date')}
            date={formData.branch_customer_date}
            allowClear={false}
            picker="date"
          />
        </Col>
        <Col md={24} xs={24}>
          <AppSelect
            name="branch_customer_reason"
            label="เหตุผล"
            isRequired={isRequired('branch_customer_reason')}
            isError={isError('branch_customer_reason')}
            value={formData.branch_customer_reason}
            onChange={onUpdateData}
            menu={getReasonhMenuSelect(reasonList)}
          />
        </Col>
        <Col span={24}>
          <AppInput
            name="branch_customer_note"
            label="หมายเหตุ"
            isRequired={isRequired('branch_customer_note')}
            isError={isError('branch_customer_note')}
            value={formData.branch_customer_note}
            onChange={onUpdateData}
          />
        </Col>
      </Row>
    )
  }

  return (
    <ConfirmWithChild
      title={'ยืนยันการส่งเคสไปยังสาขา'}
      isShow={isShow}
      handleOk={() => {
        if (!validateForm()) {
          message.error('กรุณาระบุข้อมูลให้ครบถ้วน')
          return
        }
        onSendToBranch()
      }}
      handleCancel={() => {
        onClose()
      }}
      child={getDailogChild()}
      confirmBtnTxt="ยืนยัน"
    />
  )
}

const ExternalLink = styled.a`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectLogsEvent(state),
    list: selectLogsData(state),
    loginData: selectLoginData(state),
    branchList: selectBranchList(state),
    reasonList: selectReasonList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(SendToBranchModal)
