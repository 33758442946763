import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { cloneDeep } from 'lodash'
import styled from 'styled-components'
import Theme, { MyTheme } from 'Components/common/Theme'
import { Card, Col, message, Row, Space, Statistic } from 'antd'
import { PrimaryButton } from 'Components/button'
import {
  formValidation,
  formatDateTime,
  isFormRequired,
  isFormError,
  numberWithComma,
  getSaleTaskStatus,
  formatDateWithoutTime,
  toDateQuery,
  getBranchMenuSelect,
  getMenuSelect,
  getReasonhMenuSelect,
} from 'helpers/format'
import { AssignReq, BranchReq, DetailReq, ReasonReq, UpdateReq, UpdateStatusReq } from 'Store/sale-task/types'
import {
  selectAssignTaskEvent,
  selectBranchList,
  selectDetail,
  selectDetailEvent,
  selectReasonList,
  selectTaskEvent,
} from 'Store/sale-task/selector'
import { clearEventAction } from 'Store/sale-task/actions'
import EventModal from 'Components/modal/EventModal'
import LoadingView from 'Components/modal/LoadingView'
import { DetailDrawer } from 'Components/common/MainLayout'
import { ConfirmDailog, ConfirmQuestion, ConfirmWithChild } from 'Components/modal'
import { SaleTaskStatus } from 'helpers/constanst'
import { selectLoginData, selectUserProfile } from 'Store/user/selector'
import { InfoItem } from 'Components/text'
import BoxWrapper from 'Components/common/Layout/BoxWrapper'
import { AppDateItem, AppInput, AppSelect, AppTextArea, MultiUploadForm } from 'Components/form'
import { StatusTag } from 'Components/utilities'
import {
  updateCustomerTaskStatusAPI,
  updateCustomerTaskAPI,
  assignTaskAPI,
  getBranchListAPI,
  getReasonListAPI,
} from 'Store/sale-task/api'
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  MailOutlined,
  SaveOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import Logs from './components/Logs'
import { getAllCarloanDetailAPI, getMeCarloanDetailAPI } from 'Store/sale-task/api'
import { TaskSaleData } from 'Store/sale-task/types'
import { selectBrandList, selectModelList, selectYearList } from 'Store/chaiyo-customer/selector'
import { BrandReq, BrandSlugReq, YearData } from 'Store/chaiyo-customer/types'
import { getBrandBySlugListAPI, getBrandListAPI } from 'Store/chaiyo-customer/api'
import { getYearByModelDataAction, resetBrandDataAction } from 'Store/chaiyo-customer/actions'
import { appConfig } from 'config'
import { consoleLog } from 'helpers/utils'

type Props = ReduxProps & {
  visible: boolean
  onCloseDrawer: () => void
  onSuccess?: () => void
  id?: number
  isMe: boolean
  isAgentChatSale?: boolean
}

const CustomerTaskDetail: React.FunctionComponent<Props> = props => {
  const {
    onCloseDrawer,
    visible,
    event,
    onSuccess = () => { },
    id = 0,
    detail,
    detailEvent,
    loginData,
    isMe,
    assignTaskEvent,
    branchList,
    brandList,
    modelList,
    yearList,
    reasonList,
    isAgentChatSale = false,
  } = props

  const requireFeild = [
    'branch_customer',
    'branch_customer_date',
    'first_name',
    'last_name',
    'mobile',
    'car_brand',
    'car_model',
    'car_year',
    'amount_redbook',
    'amount_limit',
    'amount_rate',
    'car_paint',
    'car_registration',
    'branch_customer_reason',
  ]
  const initForm = {} as UpdateReq
  const [formData, setFormData] = useState(cloneDeep(initForm))
  const [formError, setFormError] = useState([] as string[])
  const [rejectDialog, setRejectDialog] = useState(false)
  const [closedDialog, setClosedDialog] = useState(false)
  const [cancelDialog, setCancelDialog] = useState(false)
  const [approveDialog, setApproveDialog] = useState(false)
  const [showLogDrawer, setShowLogDrawer] = useState(false)
  const [reasonSelected, setReasonSelected] = useState('')
  const [reasonInput, setReasonInput] = useState('')
  const [editMode, setEditMode] = useState(false)
  const editable = detail?.task_sale?.status === SaleTaskStatus.ONPROCESS
  const [activeYear, setActiveYear] = useState(undefined as YearData | undefined)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!visible) {
      setFormError([])
      setEditMode(false)
    }
    if (visible && id) {
      onGetDetail()
    }
  }, [visible])

  const initFormData = () => {
    const form = {
      images: detail?.car_loan?.images || [],
      documents: detail?.task_sale?.documents || [],
      branch_customer: detail?.task_sale?.branch_customer || '',
      branch_customer_date: detail?.task_sale?.branch_customer_date || '',
      branch_customer_note: detail?.task_sale?.branch_customer_note || '',
      branch_customer_email: detail?.task_sale?.branch_customer_email || '',
      branch_customer_reason: detail?.task_sale?.branch_customer_reason || '',
      address: detail?.car_loan?.address,
      amount_limit: detail?.car_loan?.amount_limit,
      amount_rate: detail?.car_loan?.amount_rate,
      amount_redbook: detail?.car_loan?.amount_redbook,
      car_brand: detail?.car_loan?.car_brand,
      car_model: detail?.car_loan?.car_model,
      car_paint: detail?.car_loan?.car_paint,
      car_registration: detail?.car_loan?.car_registration,
      car_year: detail?.car_loan?.car_year,
      first_name: detail?.first_name,
      last_name: detail?.last_name,
      mobile: detail?.mobile,
    } as UpdateReq
    setFormData({ ...form })
  }

  useEffect(() => {
    if (id > 0) {
      initFormData()
      onGetReasonListAPI()
    }
  }, [detail])

  useEffect(() => {
    if (formError.length > 0) {
      validateForm()
    }
  }, [formData])

  const onGetDetail = (): void => {
    const req: DetailReq = {
      id: id,
    }
    if (isMe) {
      dispatch(getMeCarloanDetailAPI(req, loginData?.access_token || '', isAgentChatSale))
    } else {
      dispatch(getAllCarloanDetailAPI(req, loginData?.access_token || ''))
    }
  }

  const onGetBranchListAPI = () => {
    const req: BranchReq = {}
    dispatch(getBranchListAPI(req, loginData?.access_token || ''))
  }

  const onGetReasonListAPI = () => {
    const req: ReasonReq = {}
    dispatch(getReasonListAPI(req, loginData?.access_token || ''))
  }

  const onUpdate = (): void => {
    const req: UpdateReq = {
      ...formData,
      branch_customer_date: toDateQuery(formData.branch_customer_date),
      id: id,
    }
    dispatch(updateCustomerTaskAPI(req, loginData?.access_token || ''))
  }

  const onUpdateStatus = (status: SaleTaskStatus, reason?: string, note?: string): void => {
    const req: UpdateStatusReq = {
      status,
      ...formData,
      branch_customer_date: toDateQuery(formData.branch_customer_date),
      reason: reason || '',
      note: note || '',
      id,
    }
    dispatch(updateCustomerTaskStatusAPI(req, loginData?.access_token || '', isAgentChatSale))
  }

  const onAssignTask = (): void => {
    const req: AssignReq = {
      id,
    }
    dispatch(assignTaskAPI(req, loginData?.access_token || ''))
  }

  const onCancel = (reason: string): void => {
    onUpdateStatus(SaleTaskStatus.CANCEL, reason)
  }

  const onClose = (): void => {
    onUpdateStatus(SaleTaskStatus.TOBRANCH, '')
  }

  const onSave = () => {
    if (!validateForm()) {
      message.error('กรุณาระบุข้อมูลให้ครบถ้วน')
      return
    }
    // setEditMode(false)
    onUpdate()
  }

  const onUpdateData = (val: any, key: string) => {
    if (key === 'mobile' && val.length > 10) return
    setFormData({
      ...formData,
      [key]: val,
    })
  }

  const onGetBrandList = (): void => {
    const req: BrandReq = {}
    dispatch(getBrandListAPI(req, loginData?.access_token || ''))
  }

  const onGetBrandBySlugList = (slug: string): void => {
    const req: BrandSlugReq = {
      slug,
    }
    dispatch(getBrandBySlugListAPI(req, loginData?.access_token || ''))
  }

  const getYearData = (): void => {
    dispatch(getYearByModelDataAction({ model: formData.car_model || '' }))
  }

  const resetData = (): void => {
    formData.car_model = ''
    formData.car_year = ''
    dispatch(resetBrandDataAction())
  }

  useEffect(() => {
    if (visible && editMode) {
      onGetBrandList()
    }
  }, [id, visible, editMode])

  useEffect(() => {
    if (formData.car_brand) {
      onGetBrandBySlugList(formData.car_brand || '')
    } else {
      resetData()
    }
  }, [formData.car_brand])

  useEffect(() => {
    if (formData.car_model) {
      getYearData()
    }
  }, [formData.car_model, modelList])

  useEffect(() => {
    if (!visible) return
    if (formData.car_year && formData.car_model) {
      const year = yearList && yearList.find((item: YearData) => item.description === formData.car_year)
      setActiveYear(year)
    } else {
      setActiveYear(undefined)
    }
  }, [formData.car_year, yearList, id, visible])

  const onUpdateCarBrand = (value: any, key: string) => {
    setActiveYear(undefined)
    setFormData({
      ...formData,
      [key]: value,
      car_model: '',
      car_year: '',
      amount_redbook: 0,
    })
  }

  const findEmailByName = (val: string) => {
    const branch = branchList && branchList.find(item => item.name === val)
    const email = []
    if (branch?.email_center) {
      email.push(branch?.email_center)
    }
    if (branch?.email_staff1) {
      email.push(branch?.email_staff1)
    }
    if (branch?.email_staff2) {
      email.push(branch?.email_staff2)
    }
    if (branch?.email_staff3) {
      email.push(branch?.email_staff3)
    }
    return email.join(',')
  }

  const onUpdateBranch = (value: any) => {
    setFormData({
      ...formData,
      branch_customer: value,
      branch_customer_email: findEmailByName(value),
    })
  }

  const onUpdateCarYear = (value: any, key: string) => {
    const year = yearList && yearList.find((item: YearData) => item.year === value)
    setFormData({
      ...formData,
      [key]: value,
      amount_redbook: parseFloat(year?.price || ''),
    })
  }

  const onUpdateCarModel = (value: any, key: string) => {
    setActiveYear(undefined)
    setFormData({
      ...formData,
      [key]: value,
      car_year: '',
      amount_redbook: 0,
    })
  }

  const onUpdateCarPrice = (value: any) => {
    setFormData({
      ...formData,
      amount_redbook: parseFloat(value || 0),
    })
  }

  const validateForm = () => {
    const error = formValidation(requireFeild, formData)
    setFormError([...error])
    consoleLog('error', error)
    return error.length > 0 ? false : true
  }

  const isRequired = (name: string) => {
    return isFormRequired(requireFeild, name)
  }

  const isError = (name: string) => {
    return isFormError(formError, name)
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onEventSuccess = (): void => {
    resetReasonForm()
    clearEvent()
    onGetDetail()
    setCancelDialog(false)
    setApproveDialog(false)
    setRejectDialog(false)
    setClosedDialog(false)
    onSuccess()
    setEditMode(false)
  }

  const onAssignEventSuccess = (): void => {
    clearEvent()
    onCloseDrawer()
    onSuccess()
  }

  const onAssignEventError = (): void => {
    clearEvent()
    onCloseDrawer()
    onSuccess()
  }

  const valStyle = {
    color: Theme.colors.black,
    fontFamily: Theme.fontFamily.regular,
    fontSize: Theme.typography.body4,
  }

  const renderPriceChoices = (key: string, name: string) => {
    return (
      <div onClick={() => onUpdateCarPrice(activeYear?.[key] || 0)}>
        <CardPrice className={formData.amount_redbook == (activeYear?.[key] || 0) ? 'actived' : ''}>
          <Statistic
            title={name}
            value={activeYear?.[key] || 0}
            suffix={'บาท'}
            valueStyle={valStyle}
            formatter={(val: any) => numberWithComma(parseFloat(val) || 0)}
          />
        </CardPrice>
      </div>
    )
  }

  const getFooter = (status?: SaleTaskStatus) => {
    const btn = [] as any[]
    // btn.push(<PrimaryButton text="ปิด" btnType="Cancel" onClick={onCloseDrawer} />)
    if (isMe) {
      switch (status) {
        case SaleTaskStatus.INQUE:
          btn.push(
            <PrimaryButton
              key={`${status}-approve`}
              text="On Process"
              btnType="Primary"
              onClick={() => onUpdateStatus(SaleTaskStatus.ONPROCESS)}
            />,
          )

          break
        case SaleTaskStatus.ONPROCESS:
          if (editMode) return
          btn.push(
            <PrimaryButton
              text="ยกเลิกเคส"
              disabled={editMode}
              icon={<CloseOutlined />}
              btnType="Reject"
              onClick={() => setCancelDialog(true)}
            />,
          )
          btn.push(
            <PrimaryButton
              key={`${status}-approve`}
              text="ยืนยันส่งไปยังสาขา"
              btnType="Primary"
              disabled={editMode}
              icon={<MailOutlined />}
              onClick={() => {
                if (!validateForm()) {
                  message.error('กรุณาระบุข้อมูลสาขาให้ครบถ้วนก่อนยืนยันส่งไปยังสาขา')
                  return
                }
                setApproveDialog(true)
              }}
            />,
          )
          break

        case SaleTaskStatus.TOBRANCH:
          btn.push(
            <PrimaryButton
              text="ปฏิเสธเคส"
              icon={<CloseOutlined />}
              btnType="Reject"
              onClick={() => setRejectDialog(true)}
            />,
          )
          btn.push(
            <PrimaryButton
              key={`${status}-approve`}
              text="ปิดเคส"
              btnType="Approve"
              icon={<CheckOutlined />}
              onClick={() => setClosedDialog(true)}
            />,
          )
          break

        default:
          break
      }
    } else {
      switch (status) {
        case SaleTaskStatus.INQUE:
          btn.push(
            <PrimaryButton
              key={`${status}-approve`}
              text="รับงาน"
              btnType="Primary"
              onClick={onAssignTask}
            />,
          )

          break
        default:
          break
      }
    }

    return (
      <Space size="middle" align="baseline">
        {btn}
      </Space>
    )
  }

  const FooterContent = getFooter(detail?.task_sale?.status)

  // const FooterContent = (
  //   <Space size="middle" align="baseline">
  // <PrimaryButton
  //   text="ยกเลิกเคส"
  //   icon={<CloseOutlined />}
  //   btnType="Reject"
  //   onClick={() => setRejectDialog(true)}
  // />
  //     <PrimaryButton
  //       text="ยืนยันส่งไปยังสาขา"
  //       btnType="Approve"
  //       icon={<CheckOutlined />}
  //       onClick={() => setApproveDialog(true)}
  //     />
  //   </Space>
  // )

  const renderInfo = (title: string, value: any, span: number = 15) => {
    return <InfoItem title={title} value={value} span={span} />
  }

  const renderInfoRow = (item1: any, item2: any) => {
    return (
      <Col span={24}>
        <Row justify="space-between">
          <Col md={12} xs={24}>
            {item1}
          </Col>
          <Col md={12} xs={24}>
            {item2}
          </Col>
        </Row>
      </Col>
    )
  }

  const ConfirmApproveDailog = (
    <ConfirmDailog
      title="ยืนยันการส่งเคสไปยังสาขา"
      description="คุณแน่ใจที่จะส่งเคสนี้ไปยังสาขาหรือไม่?"
      confirmBtnTxt="ยืนยัน"
      isShow={approveDialog}
      handleOk={onClose}
      handleCancel={() => setApproveDialog(false)}
      loading={event.IsLoading}
    />
  )

  const getDailogChild = () => {
    return (
      <Row>
        <Col md={24} xs={24}>
          <AppSelect
            name="reasonSelected"
            label="เหตุผล"
            placeHolder="กรุณาเลือกเหตุผล"
            isRequired={true}
            isError={false}
            value={reasonSelected}
            onChange={(val: string) => setReasonSelected(val)}
            menu={getReasonhMenuSelect(reasonList)}
            allowClear={false}
          />
        </Col>
        <Col md={24} xs={24}>
          <AppInput
            name="reasonInput"
            label="หมายเหตุ"
            isRequired={false}
            isError={false}
            value={reasonInput}
            onChange={setReasonInput}
          />
        </Col>
      </Row>
    )
  }

  const resetReasonForm = () => {
    setReasonSelected('')
    setReasonInput('')
  }

  const ConfirmCancelDailog = (
    // <ConfirmQuestion
    //   title="ยกเลิกเคส"
    //   label="เหตุผลที่ยกเลิกเคส"
    //   placeholder="กรุณาระบุเหตุผลที่ยกเลิกเคส"
    //   confirmBtnTxt="ยืนยันยกเลิก"
    //   isShow={cancelDialog}
    //   handleOk={(remark: string) => onCancel(remark)}
    //   handleCancel={() => setCancelDialog(false)}
    //   loading={event.IsLoading}
    // />
    <ConfirmWithChild
      title={'ยกเลิกเคส'}
      isShow={cancelDialog}
      handleOk={() => {
        if (reasonSelected.trim() === '') {
          message.error('กรุณาเลือกเหตุผล')
          return
        }
        onUpdateStatus(SaleTaskStatus.CANCEL, reasonSelected, reasonInput)
      }}
      handleCancel={() => {
        resetReasonForm()
        setCancelDialog(false)
      }}
      child={getDailogChild()}
      confirmBtnTxt="ยืนยัน"
    />
  )

  const ConfirmRejectDailog = (
    <ConfirmWithChild
      title={'ยืนยันการปฏิเสธเคสนี้'}
      isShow={rejectDialog}
      handleOk={() => {
        if (reasonSelected.trim() === '') {
          message.error('กรุณาเลือกเหตุผล')
          return
        }
        onUpdateStatus(SaleTaskStatus.REJECTED, reasonSelected, reasonInput)
      }}
      handleCancel={() => {
        resetReasonForm()
        setRejectDialog(false)
      }}
      child={getDailogChild()}
      confirmBtnTxt="ยืนยัน"
    />
  )

  const ConfirmClosedDailog = (
    <ConfirmWithChild
      title={'ยืนยันการปิดเคสนี้'}
      isShow={closedDialog}
      handleOk={() => {
        if (reasonSelected.trim() === '') {
          message.error('กรุณาเลือกเหตุผล')
          return
        }
        onUpdateStatus(SaleTaskStatus.CLOSED, reasonSelected, reasonInput)
      }}
      handleCancel={() => {
        resetReasonForm()
        setClosedDialog(false)
      }}
      child={getDailogChild()}
      confirmBtnTxt="ยืนยัน"
    />
  )

  const branchFormLeft = (
    <Row justify="space-between" gutter={[16, 1]}>
      {/* <AppInput
          name="branch_customer"
          label="สาขาที่ลูกค้าต้องการเข้าดำเนินการ"
          isRequired={isRequired('branch_customer')}
          isError={isError('branch_customer')}
          value={formData.branch_customer}
          onChange={onUpdateData}
        /> */}
      <Col md={24} xs={24}>
        <AppSelect
          name="branch_customer"
          label="สาขาที่ลูกค้าต้องการเข้าดำเนินการ"
          isRequired={isRequired('branch_customer')}
          isError={isError('branch_customer')}
          value={formData.branch_customer}
          onChange={onUpdateBranch}
          menu={getBranchMenuSelect(branchList)}
        />
      </Col>
      <Col md={24} xs={24}>
        <AppSelect
          name="branch_customer_reason"
          label="เหตุผล"
          isRequired={isRequired('branch_customer_reason')}
          isError={isError('branch_customer_reason')}
          value={formData.branch_customer_reason}
          onChange={onUpdateData}
          menu={getReasonhMenuSelect(reasonList)}
        />
      </Col>
      {/* <Col md={24} xs={24}>
        <AppInput
          name="branch_customer_email"
          label="อีเมลสาขา"
          isRequired={isRequired('branch_customer_email')}
          errorMsg={isError('email_format') ? 'รูปแบบอีเมลให้ถูกต้อง' : 'อีเมล'}
          isError={isError('branch_customer_email') || isError('email_format')}
          value={formData.branch_customer_email}
          onChange={onUpdateData}
        />
      </Col> */}
    </Row>
  )

  const branchFormRight = (
    <Row justify="space-between" gutter={[16, 1]}>
      <Col span={24}>
        <AppDateItem
          label="วันที่ลูกค้าต้องการเข้า"
          placeHolder="วันที่ลูกค้าต้องการเข้า"
          isRequired={isRequired('branch_customer_date')}
          isError={isError('branch_customer_date')}
          onChange={(date: string) => onUpdateData(date, 'branch_customer_date')}
          date={formData.branch_customer_date}
          allowClear={false}
          picker="date"
        />
      </Col>
      <Col span={24}>
        <AppInput
          name="branch_customer_note"
          label="หมายเหตุ"
          isRequired={isRequired('branch_customer_note')}
          isError={isError('branch_customer_note')}
          value={formData.branch_customer_note}
          onChange={onUpdateData}
        />
      </Col>
    </Row>
  )

  const renderStatus = (value?: TaskSaleData) => {
    const result = getSaleTaskStatus(value?.status || '')
    // if (value?.status === SaleTaskStatus.CANCEL) {
    //   return (
    //     <Space direction="vertical">
    //       <StatusTag color={result.color} text={result.text} />
    //       {value?.reason && <Reason>เหตุผล : {value?.reason}</Reason>}
    //       {value?.note && <Reason>หมายเหตุ : {value?.note}</Reason>}
    //     </Space>
    //   )
    // } else {
    //   return (
    //     <Space direction="vertical">
    //       <StatusTag color={result.color} text={result.text} />
    //       {value?.reason && <Note>เหตุผล : {value?.reason}</Note>}
    //       {value?.note && <Note>หมายเหตุ : {value?.note}</Note>}
    //     </Space>
    //   )
    // }
    return <StatusTag color={result.color} text={result.text} />
  }

  const taskInfo = (
    <BoxWrapper title="">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(renderInfo('หมายเลขเคส', detail?.ams_case_no), null)}
        {renderInfoRow(
          renderInfo(
            'Agent ที่สร้างเคส',
            `${detail?.user?.profile?.first_name}  ${detail?.user?.profile?.last_name}`,
          ),
          renderInfo('สถานะงาน', renderStatus(detail?.task_sale)),
        )}
        {renderInfoRow(
          renderInfo('วันที่สร้างข้อมูล', formatDateTime(detail?.created_at || '')),
          detail?.task_sale?.reason ? renderInfo('เหตุผล', detail?.task_sale?.reason) : null,
        )}
        {renderInfoRow(
          null,
          detail?.task_sale?.note ? renderInfo('หมายเหตุ', detail?.task_sale?.note) : null,
        )}
      </Row>
    </BoxWrapper>
  )

  const carInfo = (
    <BoxWrapper title="ประกันชีวิตที่ลูกค้าสนใจ">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(
          renderInfo('สิทธิประโยชน์และความคุ้มครอง', detail?.car_loan?.car_brand || ''),
          renderInfo('ผลิตภัณฑ์ประกันชีวิต', detail?.car_loan?.car_model),
        )}
        {renderInfoRow(
          renderInfo('ทุนประกัน', detail?.car_loan?.car_year),
          //renderInfo('ลูกค้าอยู่ในเขตพื้นที่บริการของตนเองหรือไม่', `${numberWithComma(detail?.car_loan?.amount_redbook, true)} บาท`),
          renderInfo(' ', ` `),
        )}
      </Row>
    </BoxWrapper>
  )

  const carForm = (
    <BoxWrapper title="ประกันชีวิตที่ลูกค้าสนใจ">
      <Row justify="start" align="top" gutter={[8, 8]}>
        {/* <Col span={24}>
          <Name>ข้อมูลรถยนต์</Name>
        </Col> */}
        <Col md={12} xs={24}>
          <AppSelect
            name="car_brand"
            label="สิทธิประโยชน์และความคุ้มครอง"
            isRequired={isRequired('car_brand')}
            isError={isError('car_brand')}
            value={formData.car_brand}
            onChange={onUpdateCarBrand}
            menu={getMenuSelect(brandList, 'name', 'slug')}
            allowClear={false}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppSelect
            name="car_model"
            label="ผลิตภัณฑ์ประกันชีวิต"
            isRequired={isRequired('car_model')}
            isError={isError('car_model')}
            value={formData.car_model}
            onChange={onUpdateCarModel}
            menu={getMenuSelect(modelList, 'name', 'name')}
            disabled={formData.car_brand == ''}
            allowClear={false}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppSelect
            name="car_year"
            label="ทุนประกัน"
            isRequired={isRequired('car_year')}
            isError={isError('car_year')}
            value={formData.car_year}
            onChange={onUpdateCarYear}
            menu={getMenuSelect(yearList, 'description', 'description')}
            disabled={formData.car_model == ''}
            allowClear={false}
          />
        </Col>
        {activeYear && (
          <Col md={12} xs={24}>
            <Row>
              <Col md={24} xs={24}>
                <CarName> ลูกค้าอยู่ในเขตพื้นที่บริการของตนเองหรือไม่ : {activeYear?.description || ''} </CarName>
              </Col>
              <Col md={24} xs={24}>
                {/* {renderPriceChoices('price', 'ราคา')} */}
                {renderPriceChoices('l1', 'ราคา L1')}
                {renderPriceChoices('l2', 'ราคา L2')}
                {/* {renderPriceChoices('l3', 'ราคา L3')} */}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </BoxWrapper>
  )

  const editButton = () => {
    return (
      <PrimaryButton
        text="แก้ไขข้อมูล"
        icon={<EditOutlined />}
        btnType="Primary2"
        onClick={() => {
          onGetBranchListAPI()
          setEditMode(true)
        }}
      />
    )
  }

  const saveButton = () => {
    return (
      <Space>
        <PrimaryButton
          text="ยกเลิก"
          btnType="Cancel"
          onClick={() => {
            initFormData()
            setEditMode(false)
          }}
        />
        <PrimaryButton text="บันทึกข้อมูล" icon={<SaveOutlined />} onClick={onSave} />
      </Space>
    )
  }

  const documentForm = (
    <BoxWrapper
      title="ข้อมูลรูปภาพและเอกสาร"
    // right={isMe && editable && (!editMode ? editButton() : saveButton())}
    >
      <Row gutter={[16, 16]} justify="center">
        <Col md={12} xs={24}>
          <MultiUploadForm
            label="รูปภาพเพิ่มเติมอื่นๆ"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={onUpdateData}
            files={formData.images}
            listType="picture-card"
            name="images"
            readOnly={false}
            maxFileSize={5000}
          />
        </Col>
        <Col md={12} xs={24}>
          <MultiUploadForm
            label="เอกสารเพิ่มเติมอื่นๆ"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={onUpdateData}
            files={formData.documents}
            listType="picture-card"
            name="documents"
            readOnly={false}
            maxFileSize={5000}
          />
        </Col>
      </Row>
    </BoxWrapper>
  )

  const documentInfo = (
    <BoxWrapper
      title="ข้อมูลรูปภาพและเอกสาร"
    // right={isMe && editable && !editMode && editButton()}
    >
      <Row justify="space-between" gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <MultiUploadForm
            label="รูปภาพเพิ่มเติมอื่นๆ"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={() => { }}
            files={detail?.car_loan?.images || []}
            listType="picture-card"
            name="photos"
            readOnly={true}
            maxFileSize={5000}
          />
        </Col>
        <Col md={12} xs={24}>
          <MultiUploadForm
            label="เอกสารเพิ่มเติมอื่นๆ"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={() => { }}
            files={detail?.task_sale?.documents || []}
            listType="picture-card"
            name="photos"
            readOnly={true}
            maxFileSize={5000}
          />
        </Col>
      </Row>
    </BoxWrapper>
  )

  const branchForm = (
    <BoxWrapper
      title="ข้อมูลเข้าสาขา"
    //  right={isMe && editable && (!editMode ? editButton() : saveButton())}
    >
      <Row gutter={[16, 16]} justify="center">
        {/* <Col md={12} xs={24}>
          <MultiUploadForm
            label="รูปภาพรถยนต์"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={onUpdateData}
            files={formData.images}
            listType="picture-card"
            name="images"
            readOnly={false}
            maxFileSize={5000}
          />
        </Col>
        <Col md={12} xs={24}>
          <MultiUploadForm
            label="เอกสารรถยนต์"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={onUpdateData}
            files={formData.documents}
            listType="picture-card"
            name="documents"
            readOnly={false}
            maxFileSize={5000}
          />
        </Col> */}
        {/* <Col span={24}>ข้อมูลเข้าสาขา*</Col> */}
        <Col md={12} xs={24}>
          {branchFormLeft}
        </Col>
        <Col md={12} xs={24}>
          {branchFormRight}
        </Col>
      </Row>
    </BoxWrapper>
  )

  const branchInfo = (
    <BoxWrapper
      title="ข้อมูลเข้าสาขา"
    //right={isMe && editable && !editMode && editButton()}
    >
      <Row justify="space-between" gutter={[16, 16]}>
        {(detail?.task_sale?.status === SaleTaskStatus.ONPROCESS ||
          detail?.task_sale?.status === SaleTaskStatus.TOBRANCH) && (
            <>
              {/* <Col span={24}>ข้อมูลเข้าสาขา</Col> */}
              <Col span={24}>
                {renderInfo('สาขาที่ลูกค้าต้องการเข้าดำเนินการ', detail?.task_sale?.branch_customer || '')}
              </Col>
              <Col span={24}>
                {renderInfo(
                  'วันที่ลูกค้าต้องการเข้า',
                  formatDateWithoutTime(detail?.task_sale?.branch_customer_date || ''),
                )}
              </Col>
              <Col span={24}>{renderInfo('เหตุผล', detail?.task_sale?.branch_customer_reason || '')}</Col>
              <Col span={24}>{renderInfo('หมายเหตุ', detail?.task_sale?.branch_customer_note || '')}</Col>
            </>
          )}
      </Row>
    </BoxWrapper>
  )

  const getExternalLink = () => {
    return (
      <ExternalLink href={appConfig.externalChaiyoLink} target="_blank" rel="noopener noreferrer">
        &nbsp;
        <SearchOutlined />รายละเอียด และคู่มือผลิตภัณฑ์ประกันชีวิต
      </ExternalLink>
    )
  }

  const customerInfo = (
    <BoxWrapper title="ข้อมูลลูกค้า">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(
          renderInfo('ชื่อ', detail?.first_name || ''),
          renderInfo('นามสกุล', detail?.last_name || ''),
        )}
        {renderInfoRow(
          renderInfo('เบอร์โทรศัพท์', detail?.mobile || ''),
          renderInfo('ที่อยู่', detail?.car_loan?.address || ''),
        )}
        {renderInfoRow(
          renderInfo(
            'อายุ(ปี)',
            //`${numberWithComma(detail?.car_loan?.amount_limit || 0, true)} `,
            //`${parseInt(detail?.car_loan?.amount_limit || 0)}`
            //`${String(parseInt(detail?.car_loan?.amount_limit || 0))}` // แปลงเป็น string
            //`${parseInt(detail?.car_loan?.amount_limit || 0).toString()}`
            detail?.car_loan?.amount_limit || ''
          ),
          renderInfo(
            'ปีพ.ศ.เกิด',
            //`${numberWithComma(detail?.car_loan?.amount_rate || 0, true)} `,
            //`${parseInt(detail?.car_loan?.amount_rate || 0)}`
            //`${String(parseInt(detail?.car_loan?.amount_rate || 0))}` // แปลงเป็น string
            //`${parseInt(detail?.car_loan?.amount_rate || 0).toString()}`
            detail?.car_loan?.amount_rate || ''
          ),
        )}
        {renderInfoRow(
          renderInfo('อาชีพ', detail?.car_loan?.car_paint || ''),
          renderInfo('LINE ID', detail?.car_loan?.car_registration || ''),
        )}
        {/* <Col span={24}>
          <MultiUploadForm
            label="รูปภาพรถยนต์"
            description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
            token={''}
            onUpdateData={() => {}}
            files={detail?.car_loan?.images || []}
            listType="picture-card"
            name="photos"
            readOnly={true}
            maxFileSize={5000}
          />
        </Col> */}
      </Row>
    </BoxWrapper>
  )

  const customerForm = (
    <BoxWrapper title="ข้อมูลลูกค้า">
      <Row justify="space-between" gutter={[8, 8]}>
        <Col md={12} xs={24}>
          <AppInput
            name="first_name"
            label="ชื่อ"
            isRequired={isRequired('first_name')}
            isError={isError('first_name')}
            value={formData.first_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="last_name"
            label="นามสกุล"
            isRequired={isRequired('last_name')}
            isError={isError('last_name')}
            value={formData.last_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="mobile"
            label="เบอร์โทรศัพท์"
            placeHolder="ตัวอย่าง 0888888888"
            isRequired={isRequired('mobile')}
            isError={isError('mobile')}
            value={formData.mobile}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppTextArea
            label="ที่อยู่"
            placeHolder="ที่อยู่"
            isRequired={isRequired('address')}
            isError={isError('address')}
            name="address"
            rows={2}
            value={formData.address}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="amount_limit"
            label="วันเดือนปีเกิด"
            isRequired={isRequired('amount_limit')}
            isError={isError('amount_limit')}
            value={formData.amount_limit}
            onChange={onUpdateData}
            type="number"
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="amount_rate"
            label="อาชีพ"
            isRequired={isRequired('amount_rate')}
            isError={isError('amount_rate')}
            value={formData.amount_rate}
            onChange={onUpdateData}
            type="number"
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="car_paint"
            label="อีเมล"
            isRequired={isRequired('car_paint')}
            isError={isError('car_paint')}
            value={formData.car_paint}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="car_registration"
            label="LINE ID"
            isRequired={isRequired('car_registration')}
            isError={isError('car_registration')}
            value={formData.car_registration}
            onChange={onUpdateData}
          />
        </Col>
      </Row>
    </BoxWrapper>
  )

  const title = (
    <Space align="start">
      <div>
        <MyTitle>
          หมายเลขเคส : {detail?.ams_case_no} ({detail?.first_name} {detail?.last_name})
        </MyTitle>
      </div>
    </Space>
  )

  const LogsDrawer = <Logs isShow={showLogDrawer} onClose={() => setShowLogDrawer(false)} id={id} />

  return (
    <DetailDrawer
      title={title}
      onCloseDrawer={onCloseDrawer}
      visible={visible}
      footer={FooterContent}
      width="85%"
      maskClosable={true}
    >
      <LoadingView isShow={detailEvent.IsLoading} />
      <EventModal event={[event]} enable={true} successCalback={onEventSuccess} errorCalback={clearEvent} />
      <EventModal
        event={[assignTaskEvent]}
        enable={true}
        successCalback={onAssignEventSuccess}
        errorCalback={onAssignEventError}
      />
      <Row gutter={[16, 16]}>
        <Col md={24} xs={24}>
          {taskInfo}
        </Col>
        <Col md={24} xs={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <Header>รายละเอียดงาน {getExternalLink()}</Header>
            </Col>
            <Col>{isMe && editable && (!editMode ? editButton() : saveButton())}</Col>
          </Row>
        </Col>
        <Col md={24} xs={24}>
          {editMode ? carForm : carInfo}
        </Col>
        <Col span={24}> {editMode ? customerForm : customerInfo}</Col>
        <Col md={24} xs={24}>
          {editMode ? documentForm : documentInfo}
        </Col>
        <Col md={24} xs={24}>
          {(detail?.task_sale?.status === SaleTaskStatus.ONPROCESS ||
            detail?.task_sale?.status === SaleTaskStatus.TOBRANCH) &&
            (editMode ? branchForm : branchInfo)}
        </Col>
      </Row>
      {ConfirmCancelDailog}
      {ConfirmRejectDailog}
      {ConfirmClosedDailog}
      {ConfirmApproveDailog}
      {LogsDrawer}
    </DetailDrawer>
  )
}

const MyTitle = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.darkBlue};
`

const Header = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.darkBlue};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.h5};
`

const Reason = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.error};
`

const Note = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.black};
`

const CarName = styled.span`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.medium};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
`

const ExternalLink = styled.a`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
`

const CardPrice = styled(Card.Grid)`
  width: 50%;
  padding: 12px;
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
  .ant-statistic-title {
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body5};
  }

  &.actived {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
    span,
    div {
      color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
    }
  }

  @media only screen and (max-width: 720px) {
    width: 50%;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectTaskEvent(state),
    detail: selectDetail(state),
    detailEvent: selectDetailEvent(state),
    loginData: selectLoginData(state),
    userProfile: selectUserProfile(state),
    assignTaskEvent: selectAssignTaskEvent(state),
    branchList: selectBranchList(state),
    brandList: selectBrandList(state),
    modelList: selectModelList(state),
    yearList: selectYearList(state),
    reasonList: selectReasonList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(CustomerTaskDetail)
